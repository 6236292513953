import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

// Components.
import PageSection from '../../Elements/PageSection';
import Row, { Col } from '../../Elements/Grid';

// Form definition.
import useMailchimp from './useMailchimp';
import fieldDefinition from './fields';
import { useForm } from '../../Form';
import Subscribe from './Subscribe';
import Image from '../../Elements/Image';

const SignUp = ({ title, image }) => {
  const [status, sendToMailchimp] = useMailchimp();
  const { t } = useTranslation('componentSignUp');
  const [{ fields, sending }, {
    handleChange, handleFocus, handleBlur, handleSubmit,
  }] = useForm(
    fieldDefinition(t),
    sendToMailchimp,
  );

  return (
    <PageSection image className="follow-the-future">
      <Row>
        <Col filled className={classNames({ visible: status === true })}>
          {status === true ? (
            <div className="success">
              <div className="yes" />
            </div>
          ) : (
            <Image
              image={image}
              style={{
                width: '100%',
                height: '100%',
              }}
            />
          )}
        </Col>
        <Col>
          <div className="sign-up">
            <h2>{title}</h2>
            <p>{t('description.p1')}</p>
            <p>{t('description.p2')}</p>
            <Subscribe
              status={status}
              fields={fields}
              sending={sending}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              onSubmit={handleSubmit}
            />
          </div>
        </Col>
      </Row>
    </PageSection>
  );
};

SignUp.defaultProps = {
  title: 'Follow the future',
  image: 'footerSecurity',
};

SignUp.propTypes = {
  title: PropTypes.string,
  image: PropTypes.string,
};

export default SignUp;
