import React from 'react';
import PropTypes from 'prop-types';

import Item from '../Item';

const NewsLink = ({ article, next }) => (
  <div className="article-link">
    <small>{next ? '→' : '←'}</small>
    <Item small article={article} />
  </div>
);

NewsLink.defaultProps = {
  next: false,
};

NewsLink.propTypes = {
  next: PropTypes.bool,
  article: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
};

export default NewsLink;
