import { useState } from 'react';
import axios from 'axios';

export default () => {
  const [result, setResult] = useState({ status: null, message: null });

  const onClick = ({ email, name }) => axios({
    method: 'post',
    baseURL: process.env.GATSBY_PORTAL_URL,
    url: 'mailing-list',
    data: { email, name },
  }).then((callResult) => {
    const response = callResult.data;
    if (response.result === 'success') {
      setResult({
        status: true,
        message: null,
      });

      return;
    }

    // If user is already subscribed, accept it.
    if (response.msg.indexOf('already subscribed') !== -1) {
      setResult({
        status: true,
        message: null,
      });

      return;
    }

    setResult({
      status: false,
      message: response.msg,
    });
  });

  return [result.status, onClick];
};
