import { isRequired, isEmail } from '../../Form/Validation';

// Use full namespace keys for translations, as this fields can be included everywhere.
export default (t) => ({
  name: {
    name: 'name',
    label: t('componentSignUp:fields.name'),
    type: 'text',
    value: '',
    rules: [
      {
        validator: isRequired,
        message: t('componentSignUp:rules.name.required'),
      },
    ],
  },
  email: {
    name: 'email',
    label: t('componentSignUp:fields.email'),
    type: 'email',
    value: '',
    rules: [
      {
        validator: isRequired,
        message: t('componentSignUp:rules.email.required'),
      },
      {
        validator: isEmail,
        message: t('componentSignUp:rules.email.email'),
      },
    ],
  },
});
