import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { InputText } from '../../../Form';
import Icon from '../../../Elements/Icon';

const Subscribe = ({
  fields, onChange, onFocus, onBlur, onSubmit, sending, status,
}) => {
  const { t } = useTranslation('componentSignUp');

  return (
    <form className="form form-horizontal subscribe" onSubmit={onSubmit}>
      {status !== true && (
        <>
          <div className="fields">
            <InputText
              onChange={onChange}
              onFocus={onFocus}
              onBlur={onBlur}
              disabled={sending}
              {...fields.name} // eslint-disable-line react/jsx-props-no-spreading
            />
            <InputText
              onChange={onChange}
              onFocus={onFocus}
              onBlur={onBlur}
              disabled={sending}
              {...fields.email} // eslint-disable-line react/jsx-props-no-spreading
            />
          </div>
          {status === false && (
            <p className="error">
              <Icon type="exclamation-circle" />
              {` ${t('error')}`}
            </p>
          )}
          <button className="action-button" type="submit" disabled={sending}>
            {t('send')}
          </button>
        </>
      )}
      {status === true && <p className="success-message">{t('success')}</p>}
    </form>
  );
};

Subscribe.defaultProps = {
  status: null,
};

Subscribe.propTypes = {
  fields: PropTypes.shape({
    name: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    email: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  sending: PropTypes.bool.isRequired,
  status: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default Subscribe;
